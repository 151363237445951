import React, { useEffect, useState } from 'react';
import { plainTranslate } from "../../common/translate";
import client from '../../../actions/client';
import { Upload, Button, Select } from 'antd';
import 'antd/lib/modal/style/index.css';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
//import { Editor } from '@tinymce/tinymce-react';

const SectionData = props => {
    const [availableFields, setAvailableFields] = useState([]);
    const [value, setValue] = useState({});
    const [loading, setLoading] = useState(false);
    const [fileList, setFileList] = useState([]);
    const [images, setImages] = useState([]);
    const uploadButton = (
        <Button>
            <UploadOutlined /> {plainTranslate(props.locale, 'Upload')}
        </Button>
    );

    useEffect(() => {
        setAvailableFields(props.formData.availableFields);
    }, [props.formData.availableFields]);

    useEffect(() => {
        let stateValue = value ? value : {};
        stateValue.id = props.formData.type;

        setValue(stateValue);
    }, [props.formData.type]);

    useEffect(() => {
        setValue(props.input.value.sections);
    }, []);

    const change = () => {
        let menu = new Object();
        menu['sections'] = value;
        props.input.onChange(JSON.stringify(menu));
    }

    const handleChange = (e, type, subType = null, ind = null, subSubType = null, subInd = null) => {
        let stateValue = value ? value : {};

        let val = e;
        if (e.hasOwnProperty('target')) {
            val = e.target.value;
            // val = val.replace(/["'}{\\\/]/g, "");
        }
        if (subSubType && subInd !== null) {
            stateValue[type][ind][subType][subInd][subSubType] = val;
        } else if (ind !== null && subType && subInd == null && subSubType == null) {
            stateValue[type][ind][subType] = val;
        } else {
            stateValue[type.id] = val;
            stateValue['name'] = props.formData.name;
        }
        console.log("Change:", stateValue)
        setValue(stateValue);
        change();
    }

    const handleDelete = (type, ind = null, subType = null, subInd = null) => {
        let stateValue = value;
        if (subInd !== null) {
            stateValue[type][ind][subType].splice(subInd, 1);
        } else if (ind !== null && subInd == null) {
            stateValue[type].splice(ind, 1);
        }
        console.log("Delete:", stateValue)
        setValue(stateValue);
        change();
    }

    const addItemToSection = (item, key) => {
        let stateValue = value ? value : {};
        let newItem = {};
        availableFields[key]['fields'].map(function (key, index) {
            if (key.type == 'text') {
                newItem[key.id] = '';
            } else {
                newItem[key.id] = [];
            }
        });
        if (stateValue && stateValue.hasOwnProperty(item.id)) {
            stateValue[item.id].push(newItem);
        } else {
            stateValue[item.id] = [];
            stateValue[item.id].push(newItem);
        }
        console.log("Add item to section:", stateValue)
        setValue(stateValue);
        change();
    }

    const addItemToSubSection = (item, key, itemKey, subItem, subKey) => {
        let stateValue = value;
        let newItem = {};

        availableFields[key]['fields'][subKey]['fields'].map(function (field, index) {
            if (field.type == 'text') {
                newItem[field.id] = '';
            }
        });

        if (stateValue.hasOwnProperty(item.id) && stateValue[item.id][itemKey].hasOwnProperty(subItem.id)) {
            stateValue[item.id][itemKey][subItem.id].push(newItem);
        } else {
            stateValue[item.id][itemKey][subItem.id] = [];
            stateValue[item.id][itemKey][subItem.id].push(newItem);
        }
        console.log("add item to subsection:", stateValue)
        setValue(stateValue);
        change();
    }

    const handleDocumentsChange = ({ fileList }) => {
        setFileList([...fileList])
    }

    const handleDocumentsDelete = (file) => {
        let images = images;
        let updatedImages = [];
        for (let i = 0; i < images.length; i++) {
            if (images[i].name !== file.name) {
                updatedImages.push(images[i]);
            }
        }

        setImages(updatedImages)
        props.change(props.input.name, updatedImages);
    }

    const uploadImages = (options, type, subType = null, ind = null, subSubType = null, subInd = null) => {
        const { onSuccess, onError, file, onProgress } = options;
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true)

        return client
            .post('/' + props.db + '/api/settings/image/upload', formData)
            .then(response => response.data)
            .then(response => {
                setLoading(false)
                handleChange(response.url, type, subType, ind, subSubType, subInd)
            })
            .catch(err => {
                console.log(err)
                if (err.response && err.response.data && err.response.data.message) {
                    console.log(err.response.data)
                } else {
                    console.log(err)
                }
            })

    };

    return (<div>
        {availableFields && availableFields.length ? availableFields.map((item, key) => {
            return <><div className='col-md-12' key={key}>
                <label className="text-left">{plainTranslate(props.locale, item.id)}</label>
                {item.type == 'text' &&
                    <>
                        <input type="text" className="form-control" onChange={(e) => handleChange(e, item)}
                            value={value?.[item.id]}
                        />
                    </>
                }
                {item.type == 'textarea' &&
                    <textarea
                        value={value?.[item.id]}
                        onChange={(val) => handleChange(val, item)}
                    />
                }
                {item.type == 'tinymce' &&
/*                    <Editor apiKey='9e6m6n8f3yaairxxedx81e03olfhzeht5oubgv38egohvg5z'
                        value={value?.[item.id]}
                        init={{
                            plugins: 'link code lists advlist media emoticons table preview print searchreplace hr wordcount',
                            link_assume_external_targets: true,
                            file_picker_types: 'file image media',
                            // images_upload_url: config.API_URL+'/' + this.props.db + '/api/settings/image/upload/tinymce',
                            relative_urls: false,
                            toolbar: 'undo redo | bold italic | alignleft aligncenter alignright | code | bullist numlist outdent indent | undo redo | image code | media',
                            height: "200",
                            forced_root_block: "",
                            force_br_newlines: true,
                            force_p_newlines: false
                        }}
                        onEditorChange={(val) => handleChange(val, item)}
                    />*/
                    // <RteEditor 
                    //   value={value?.[item.id]}
                    //   onChange={(val) => handleChange(val, item)}
                    // />
                    <textarea
                        value={value?.[item.id]}
                        onChange={(val) => handleChange(val, item)}
                    />
                }
                {item.type == 'select' &&
                    <Select
                        className="form-control"
                        onChange={(e) => handleChange(e, item)}
                        style={{ height: 40 }}
                        value={value?.[item.id]}
                        options={item['options']}
                    />
                }
                {item.type == 'image' &&
                    <>
                        <Upload
                            multiple
                            customRequest={(files) => uploadImages(files, item.id)}
                            onRemove={handleDocumentsDelete}
                            defaultFileList={fileList}
                            fileList={fileList}
                            showUploadList={false}
                            onChange={handleDocumentsChange}
                        >
                            {fileList.length >= 8 ? null : uploadButton}
                        </Upload>
                        {value[item.id] && value[item.id][item.type] && value[item.id][item.type] ?
                            <p style={{ display: "inline-block" }}><strong>{value[item.id][item.type]}</strong></p>
                            : loading ?
                                <LoadingOutlined /> : ''
                        }
                    </>
                }
                {item.type == 'array' && value?.[item.id] && value[item.id].length && value[item.id].map((field, ind) => {
                    return <div key={ind} className='col-md-12' style={{ paddingLeft: '10px' }}>
                        <a className="text-red text-bold" onClick={() => handleDelete(item.id, ind)}>{plainTranslate(props.locale, 'Delete element')} {ind + 1} - {plainTranslate(props.locale, item.id)}</a>
                        {availableFields[key]['fields'].length && availableFields[key]['fields'].map((sectionRow, rowKey) => {
                            return <><div className='col-md-12' key={rowKey}>
                                <label className="text-left">{plainTranslate(props.locale, sectionRow['id'])}</label>
                                {sectionRow['type'] == 'text' &&
                                    <input type="text" className="form-control" onChange={(e) => handleChange(e, item.id, sectionRow['id'], ind)}
                                        value={value[item.id][ind][sectionRow['id']]}
                                    />
                                }
                                {sectionRow['type'] == 'select' &&
                                    <Select
                                        showSearch filterOption={true} optionFilterProp="label"
                                        className="form-control"
                                        onChange={(e) => handleChange(e, item.id, sectionRow['id'], ind)}
                                        style={{ height: 40 }}
                                        value={value?.[item.id]?.[ind]?.[sectionRow['id']]}
                                        options={sectionRow['options']}
                                    />
                                }
                                {sectionRow['type'] == 'image' &&
                                    <>
                                        <Upload
                                            multiple
                                            customRequest={(files) => uploadImages(files, item.id, sectionRow.id, ind)}
                                            onRemove={handleDocumentsDelete}
                                            defaultFileList={fileList}
                                            fileList={fileList}
                                            showUploadList={false}
                                            onChange={handleDocumentsChange}
                                        >
                                            {fileList.length >= 8 ? null : uploadButton}
                                        </Upload>
                                        {value[item.id][ind] && value[item.id][ind][sectionRow['type']] ?
                                            <p style={{ display: "inline-block" }}><strong>{value[item.id][ind][sectionRow['type']]}</strong></p>
                                            : loading ?
                                                <LoadingOutlined /> : ''
                                        }
                                    </>
                                }
                                {sectionRow['type'] == 'array' && value[item.id][ind][sectionRow['id']] && value[item.id][ind][sectionRow['id']].length ? value[item.id][ind][sectionRow['id']].map((field1, ind1) => {
                                    return <div className='col-md-12' key={ind1} style={{ paddingLeft: '10px' }}>
                                        <a className="text-red text-bold" onClick={() => handleDelete(item.id, ind, sectionRow['id'], ind1)}>{plainTranslate(props.locale, 'Delete element')} {ind1 + 1} - {plainTranslate(props.locale, sectionRow['id'])}</a>
                                        {sectionRow['fields'] && sectionRow['fields'].length && sectionRow['fields'].map((sectionRow1, rowKey1) => {
                                            return <div key={rowKey1}>
                                                {sectionRow1['type'] !== 'image' && <label className="text-left">{plainTranslate(props.locale, sectionRow['fields'][rowKey1]['id'])}</label>}
                                                {sectionRow1['type'] == 'text' &&
                                                    <input key={rowKey1} type="text" className="form-control" onChange={(e) => handleChange(e, item.id, sectionRow.id, ind, sectionRow1.id, ind1)}
                                                        value={value[item.id] &&
                                                            value[item.id][ind] &&
                                                            value[item.id][ind][sectionRow.id] &&
                                                            value[item.id][ind][sectionRow.id][ind1][sectionRow1.id]
                                                        }
                                                    />
                                                }
                                                {sectionRow1['type'] == 'image' &&
                                                    <>
                                                        <Upload
                                                            multiple
                                                            customRequest={(files) => uploadImages(files, item.id, sectionRow.id, ind, sectionRow1.id, ind1)}
                                                            onRemove={handleDocumentsDelete}
                                                            defaultFileList={fileList}
                                                            fileList={fileList}
                                                            showUploadList={false}
                                                            onChange={handleDocumentsChange}
                                                        >
                                                            {fileList.length >= 8 ? null : uploadButton}
                                                        </Upload>
                                                        {value[item.id][ind] && value[item.id][ind][sectionRow1['type']] && value[item.id][ind][sectionRow1['type']][ind1][sectionRow1.id] ?
                                                            <p style={{ display: "inline-block" }}><strong>{value[item.id][ind][sectionRow1['type']][ind1][sectionRow1.id]}</strong></p>
                                                            : loading ?
                                                                <LoadingOutlined /> : ''
                                                        }
                                                    </>
                                                }
                                            </div>
                                        })
                                        }
                                    </div>
                                }) : ''
                                }
                            </div>
                                {sectionRow['type'] == 'array' &&
                                    <div className='col-md-12'><a className="button-primary" onClick={() => addItemToSubSection(item, key, ind, sectionRow, rowKey)}>{plainTranslate(props.locale, 'Add subitem to ')}{sectionRow['id']}</a></div>
                                }
                            </>
                        })
                        }
                    </div>
                })
                }
            </div>
                {item.type == 'array' &&
                    <div className='col-md-12'><a className="button-outline grey" onClick={() => addItemToSection(item, key)}>{plainTranslate(props.locale, 'Add item to ')}{item.id}</a></div>
                }
            </>
        })
            : <p>No fields added for this section</p>
        }
    </div>
    );
}

export default SectionData;
